import { Monitor } from '../webapi';

export function translateMonitor(type: Monitor): string {
  return MonitorNl[type];
}
export const MonitorNl: Record<Monitor, string> = {
  [Monitor.BookStart]: 'BoekStart',
  [Monitor.Po]: 'PO',
  [Monitor.PoSpecial]: 'PO Speciaal',
  [Monitor.Vmbo]: 'VO',
};
